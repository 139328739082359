<template>
  <div class="dialog-stri">
    <!-- 第一页 -->
    <div class="stripBox" id="pdfDom">
      <!-- 拣货内容 -->
      <div class="stri-top">
        <div class="stri-top-fu">This invoice must be completed in English. </div>
        <div class="stri-top-title">Pro Forma Invoice</div>
        <div class="stri-top-page">
          <div class="stri-gu">Page</div>
          <div class="stri-line">1</div>
          <div class="stri-gu1">of</div>
          <div class="stri-line">1</div>
        </div>
      </div>
      <table class="striptable">
        <tr>
          <td colspan="8" valign="top">
            <div class="stri-row">
              <span class="stri-label">EXPORTER: </span>
              <span class="stri-name"></span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Tax ID#: </span>
              <span class="stri-name"></span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Contact Name: </span>
              <span class="stri-name">{{ send_data.realname }}</span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Telephone No.: </span>
              <span class="stri-name">{{ send_data.mobile }}</span>
            </div>
            <div class="stri-row">
              <span class="stri-label">E-Mail: </span>
              <span class="stri-name">{{ send_data.email }}</span>
            </div>
            <div class="stri-row">
              <div class="stri-label">Company Name/Address</div>
              <div class="stri-name">{{ send_data.company }}</div>
              <div class="stri-name">{{ send_data.address_one }}</div>
              <div class="stri-name">{{ send_data.city }}, {{ send_data.province }} {{ send_data.zcode }}</div>
            </div>
            <div class="stri-row" style="margin-top:  4mm;">
              <span class="stri-name"></span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Country/Territory: </span>
              <span class="stri-name">{{ send_data.country }}</span>
            </div>
            <div class="stri-row">
              <div class="stri-label">parties to Transaction: </div>
              <div class="stri-name" style="float: left;width: 50%;margin-top: 2px;">
                <div class="stri-box">x</div>
                <div class="stri-box-name">Related</div>
              </div>
              <div class="stri-name" style="float: left;width: 50%;margin-top: 2px;">
                <div class="stri-box"></div>
                <div class="stri-box-name">Non-Related</div>
              </div>
            </div>
          </td>
          <td colspan="8" valign="top">
            <div class="stri-row">
              <div class="stri-label">Ship Date: </div>
              <div class="stri-name">{{ order_info.createtime }}</div>
            </div>
            <div class="stri-row">
              <div class="stri-label">Air Waybill No. / Tracking No.</div>
              <div class="stri-name">{{ order_info.platform_order_sn }}</div>
            </div>
            <div class="stri-row">
              <div class="stri-row-half">
                <div class="stri-label">Invoice No.: </div>
                <div class="stri-name"></div>
              </div>
              <div class="stri-row-half">
                <div class="stri-label">Purchase Order No.: </div>
                <div class="stri-name"></div>
              </div>
            </div>
            <div class="stri-row">
              <div class="stri-row-half">
                <div class="stri-label">Payment Terms: </div>
                <div class="stri-name"></div>
              </div>
              <div class="stri-row-half">
                <div class="stri-label">Bill of Lading</div>
                <div class="stri-name"></div>
              </div>
            </div>
            <div class="stri-row">
              <div class="stri-label">Purpose of Shipment: </div>
              <div class="stri-name">SOLD</div>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="8" valign="top">
            <div class="stri-row">
              <span class="stri-label">CONSIGNEE: </span>
              <span class="stri-name"></span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Tax ID#: </span>
              <span class="stri-name"></span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Contact Name: </span>
              <span class="stri-name">{{ receive_data.realname }}</span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Telephone No.: </span>
              <span class="stri-name">{{ receive_data.mobile }}</span>
            </div>
            <div class="stri-row">
              <span class="stri-label">E-Mail: </span>
              <span class="stri-name">{{ receive_data.email }}</span>
            </div>
            <div class="stri-row">
              <div class="stri-label">Company Name/Address</div>
              <div class="stri-name">{{ receive_data.company }}</div>
              <div class="stri-name">{{ receive_data.address_one }}</div>
              <div class="stri-name">{{ receive_data.city }}, {{ receive_data.province }} {{ receive_data.zcode }}</div>
            </div>
            <div class="stri-row" style="margin-top: 4mm;">
              <span class="stri-name"></span>
            </div>
            <div class="stri-row">
              <span class="stri-label">Country/Territory: </span>
              <span class="stri-name">{{ receive_data.company }}</span>
            </div>
          </td>
          <td colspan="8" valign="top">
            <div class="stri-row">
              <div class="stri-label">SOLD TO / IMPORTER (if different from Consignee): </div>
              <div class="stri-name" style="float: left;width: 50%;margin-top: 2px;">
                <div class="stri-box">x</div>
                <div class="stri-box-name">Non-Related</div>
              </div>
            </div>
            <div class="stri-row" style="margin-top: 30px;">
              <div class="stri-label">Tax ID#: </div>
              <div class="stri-name"></div>
            </div>
            <div class="stri-row" style="margin-top: 30px;">
              <span class="stri-label">Country/Territory: </span>
              <span class="stri-name">{{ receive_data.company }}</span>
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="16">
            <div class="stri-row">if three is a designated broker for this shipment, please provide contact infomation.
            </div>
            <div class="stri-row" style="margin: 2mm 0;">
              <span>Name of Broker</span>
              <span style="margin-left: 80mm;">Tel.No.</span>
              <span style="margin-left: 20mm;">Contact Name.</span>
            </div>
            <div class="stri-row">
              <div style="float: left;">Duties and Taxes Payable by</div>
              <div style="float: left;">
                <div class="stri-name" style="float: left;margin: 2px 5mm 0 5mm;">
                  <div class="stri-box"></div>
                  <div class="stri-box-name">Exporter</div>
                </div>
                <div class="stri-name" style="float: left;margin: 2px 5mm 0 0;">
                  <div class="stri-box">x</div>
                  <div class="stri-box-name">Consignee</div>
                </div>
                <div class="stri-name" style="float: left;margin: 2px 5mm 0 0;">
                  <div class="stri-box"></div>
                  <div class="stri-box-name">Other</div>
                </div>
              </div>
              <div style="float: left;">if Other, please specify</div>
            </div>
          </td>
        </tr>
        <colgroup>
          <col width='8%'>
          <col width='6.25%'>
          <col width='9.5%'>
          <col width='7.5%'>
          <col width='8.25%'>
          <col width='8%'>
          <col width='4%'>
          <col width='5%'>
          <col width='5.25%'>
          <col width='5.25%'>
          <col width='6%'>
          <col width='5%'>
          <col width='6%'>
          <col width='6%'>
          <col width='5%'>
          <col width='5%'>
        </colgroup>
        <tr style="width: 100%;background: #D8D8D8;">
          <th align="center" class="th">No. of <br>Packages</th>
          <th align="center" class="th">No. of <br>Units</th>
          <th align="center" class="th">Net Weight<br>(LBS / KGS)</th>
          <th align="center" class="th">Unit of <br>Measure</th>
          <th align="center" class="th" colspan="4">Description of Goods</th>
          <th align="center" class="th" colspan="2">Harmonized <br>Tariff Number</th>
          <th align="center" class="th" colspan="2">Country /<br> Terr. of MFR</th>
          <th align="center" class="th" colspan="2">Unit <br>Value</th>
          <th align="center" class="th" colspan="2">Total <br>Value</th>
        </tr>
        <tr :style="index == project_info.project_list.length - 1 ? 'height: 15mm' : 'height: 6mm;'" v-for="(item,index) in project_info.project_list" :key="index">
          <td align="center"></td>
          <td align="center">{{ item.num }}</td>
          <td align="center">{{ item.suttle }}</td>
          <td align="center">{{ item.unit_of }}</td>
          <td align="center" colspan="4">{{ item.product_info }}</td>
          <td align="center" colspan="2"></td>
          <td align="center" colspan="2">US</td>
          <td align="center" colspan="2">{{ item.unit_price }}</td>
          <td colspan="2" align="right">{{ item.total_price_all }}</td>
        </tr>
        <tr>
          <td align="center">Total<br>Pkgs </td>
          <td align="center">Total<br> Units </td>
          <td colspan="2">
            <div style="width: 50%;float: left;">Total Net<br> Weight </div>
            <div style="width: 50%;float: left;">(Indicate LBS/KGS)</div>
          </td>
          <td colspan="2">
            <div style="width: 50%;float: left;">Total Gross<br> Weight </div>
            <div style="width: 50%;float: left;">(Indicate LBS/KGS)</div>
          </td>
          <td colspan="6" rowspan="2" valign="top">
            <div class="stri-row">
              <div class="stri-label">Terms of Sale: </div>
              <div class="stri-name"></div>
            </div>
          </td>
          <td colspan="2">Subtotal: </td>
          <td colspan="2" align="right">{{ project_info.total_amount }}</td>
        </tr>
        <tr>
          <td align="center">{{ project_info.total_count }}</td>
          <td align="center"> {{ project_info.total_num }}</td>
          <td align="center" colspan="2">{{ project_info.total_pa_lb }} LB</td>
          <td align="center" colspan="2">{{ project_info.total_pa_lb }} LB</td>
          <td colspan="2">Insurance: </td>
          <td colspan="2" align="right">0.00</td>
        </tr>
        <tr>
          <td colspan="12" rowspan="2" valign="top">
            <div class="stri-row">
              <div class="stri-label">Special Instructions: </div>
              <div class="stri-name"></div>
            </div>
          </td>
          <td colspan="2">Freight: </td>
          <td colspan="2" align="right"> 0.00</td>
        </tr>
        <tr>
          <td colspan="2">Packing:  </td>
          <td colspan="2" align="right"> 0.00</td>
        </tr>
        <tr>
          <td colspan="12" rowspan="2" valign="top">
            <div class="stri-row">
              <div class="stri-label">Declaration Statement(s): </div>
              <div> These items are controlled by the U.S. Government and authorized for export only to the country of
                ultimate destination for use by the ultimate consignee or end-user(s) herein
                identified. They may not be resold, transferred, or otherwise disposed of, to any other country or to
                any person other than the authorized ultimate consignee or end-user(s), either in
                their original form or after being incorporated into other items, without first obtaining approval from
                the U.S. government or as otherwise authorized by U.S. law and regulations.</div>
            </div>
          </td>
          <td colspan="2">Handling: </td>
          <td colspan="2" align="right"> 0.00</td>
        </tr>
        <tr>
          <td colspan="2">Other: </td>
          <td colspan="2" align="right"> 0.00</td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="stri-row">
              <div class="stri-label">I declare that all the information contained in this invoice to be true and
                correct. </div>
            </div>
          </td>
          <td colspan="2">Invoice Total: </td>
          <td colspan="2" align="right">{{ project_info.total_amount }}</td>
        </tr>
        <tr>
          <td colspan="12">
            <div class="stri-row">
              <div class="stri-label"> Originator or Name of Company Representative if the invoice is being completed on
                behalf of a company or individual: </div>
              <div class="stri-name">{{ send_data.realname }}</div>
            </div>
          </td>
          <td colspan="2">Currency Code: </td>
          <td colspan="2" align="right">USD</td>
        </tr>
        <tr>
          <td colspan="18">
            <div class="stri-row">
              <div class="stri-label" style="float: left;">Signature / Title / Date: </div>
              <div class="stri-name" style="float: right;"> {{ order_info.createtime }}</div>
            </div>
          </td>
        </tr>
      </table>
      <div class="stri-bottom">
        <div class="stri-time">REV. 08-23-22</div>
      </div>
    </div>
    <el-button type="primary" size="small" @click="getPdf()">生成PDF</el-button>
  </div>
</template>

<script>
import { invoiceDetails } from '@/api/invoice'
export default {
  props: {
    id: {
      type: [Number,String],
      default: ''
    }
  },
  data() {
    return {
      htmlTitle: 'invoice',
      order_info: {},
      send_data: {},
      receive_data: {},
      project_info: {},
      addtime: ''
    }
  },
  mounted(){
    this.getInvoiceDetails()
  },
  methods: {
    getInvoiceDetails(){
      invoiceDetails({
        user_code:JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        id: this.id
      }).then(res => {
        console.log()
        if(res.code == 1){
          this.order_info = res.data.order_info
          this.send_data = res.data.order_info.send_data
          this.receive_data = res.data.order_info.receive_data
          this.project_info = res.data.project_info
          this.addtime = res.data.addtime
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.dialog-stri{
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}
.striptable,
.striptable tr td,
.striptable tr th {
  border: .05rem solid #000;
  margin: 0;
  padding: 5px;
  border-collapse: collapse;
  font-size: 12px;
  word-wrap: break-word;
  word-break: break-all;
  font-weight: 100;
}

.th {
  font-size: 12px !important;
  background: #fff;
}

.stripBox {
  padding: 20px;
  width: 216mm;
  height: 279mm;
  page-break-inside: avoid;
  /* 避免在容器内部进行分页 */
  margin: 0 auto;
  position: relative;
}

.striptable {
  width: 100%;
  table-layout: fixed;
  border: .05rem solid #000;
  font-size: 12px;
  color: #000;
}

/* 顶部内容 */
.stri-top {
  width: 100%;
  height: 9mm;
  position: relative;
}

.stri-top-fu {
  float: left;
  font-size: 12px;
  position: absolute;
  bottom: 2px;
  left: 0;
}

.stri-top-title {
  float: left;
  width: 100%;
  text-align: center;
  line-height: 9mm;
  font-size: 16px;
  font-weight: bold;
  height: 100%;
}

.stri-top-page {
  text-align: right;
  font-size: 12px;
  position: absolute;
  bottom: 2px;
  right: 0;
}

.stri-gu {
  float: left;
}

.stri-gu1 {
  float: left;
}

.stri-line {
  float: left;
  width: 9mm;
  border-bottom: 1px solid #000;
  text-align: center;
}

.stri-row {
  width: 100%;
  font-size: 12px;
  margin: px 0;
  overflow: hidden;
}

.stri-row-half {
  width: 50%;
  float: left;
}

.stri-label {
  font-weight: 500;
  margin: 2px 0;
}

.stri-name {
  height: 4mm;
  margin: 2px 0;
}

.stri-box {
  width: 12px;
  height: 12px;
  border: 1px solid #000;
  text-align: center;
  line-height: 11px;
  float: left;
  margin-right: 4px;
}

.stri-box-name {
  float: left;
  line-height: 12px;
}

.stri-bottom {
  width: 100%;
}

.stri-time {
  float: right;
  font-size: 12px;
  margin: 5px 10px;
}

/* 打印样式 */
@media print {

  /* 设置Letter纸张大小 */
  @page {
    size: letter;
    margin: 10mm;
  }

  .stripBox {
    page-break-after: always;
    /* 每个元素后都进行分页 */
    page-break-inside: avoid;
    /* 避免在容器内部进行分页 */
  }
}
</style>
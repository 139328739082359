<template>
  <div class="x-tops" :style="{ width: isCollapse ? '60px' : '230px' }">
    <div class="x-title">
      <span v-if="isCollapse">C</span>
      <span v-else>
        <img :src="require('@/assets/logo.png')" alt="Carousel Image" class="logo-img" @click='goToIndex'>
        <!-- 联合快递LTL系统 -->
      </span>
    </div>
    <div class="menu-wrapper">
      <el-menu
      :default-active="$route.path"
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      unique-opened
      background-color="#409EFF"
      text-color="#fff"
      active-text-color="#fff"
      router
    >
      <div v-for="(item, index) in nav" :key="index">
        <el-submenu :index="item.url" v-if="item.ischildren">
          <template slot="title">
            <i :class="item.icon"></i>
            <span slot="title" v-if="!isCollapse">{{ item.name }}</span>
          </template>
          <el-menu-item
            :index="item1.url"
            v-for="(item1, index1) in item.children"
            :key="index1"
            >{{ item1.name }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item :index="item.url" v-else>
          <i :class="item.icon"></i>
          <span slot="title">{{ item.name }}</span>
        </el-menu-item>
      </div>
    </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isCollapse: false,
      nav: [
        // {
        //   uid: 2,
        //   icon: "el-icon-document-checked",
        //   name: "国际订单",
        //   url: "/order/fplace",
        //   ischildren: false,
        // },
        // {
        //   uid: 5,
        //   icon: "el-icon-notebook-1",
        //   name: "财务管理",
        //   url: "5",
        //   ischildren: true,
        //   children: [
        //     {
        //       uid: 7,
        //       icon: "el-icon-location",
        //       name: "信用流水",
        //       url: "/finance/credit",
        //     },
        //   ],
        // },
        // {
        //   uid: 10,
        //   icon: "el-icon-setting",
        //   name: "账号管理",
        //   url: "10",
        //   ischildren: true,
        //   children: [
        //     // {
        //     //   uid: 9,
        //     //   icon: "el-icon-location",
        //     //   name: "会员中心",
        //     //   url: "/member",
        //     // },
        //     {
        //       uid: 10,
        //       icon: "el-icon-location",
        //       name: "个人信息",
        //       url: "/info",
        //     },
        //     {
        //       uid: 11,
        //       icon: "el-icon-location",
        //       name: "修改密码",
        //       url: "/password",
        //     }
        //   ],
        // },
        // ltl
        {
          uid: 1,
          icon: "el-icon-reading",
          name: "首页",
          url: "/ltl/index",
          ischildren: false,
        },       
        {
          uid: 2,
          icon: "el-icon-document-add",
          name: "新建国内快递面单",
          url: "/bill/place",
          ischildren: false,
        }, 
        {
          uid: 3,
          icon: "el-icon-document-remove",
          name: "新建国际快递面单",
          url: "/bill/fplace",
          ischildren: false,
        }, 
        {
          uid: 4,
          icon: "el-icon-tickets",
          name: "快递订单管理",
          url: "/bill/list",
          ischildren: false,
        },
        {
          uid: 5,
          icon: "el-icon-location-information",
          name: "快递地址管理",
          url: "/bill/address",
          ischildren: false,
        }, 
        {
          uid: 5,
          icon: "el-icon-printer",
          name: "发票列表",
          url: "/bill/invoice",
          ischildren: false,
        }, 
        {
          uid: 6,
          icon: "el-icon-document",
          name: "LTL订单管理",
          url: "2",
          ischildren: true,
          children: [
            {
              uid: 7,
              icon: "el-icon-tickets",
              name: "订单列表",
              url: "/ltl/orderList",
            },
          ],
        },
        {
          uid: 8,
          icon: "el-icon-coin",
          name: "LTL报价管理",
          url: "4",
          ischildren: true,
          children: [
            {
              uid: 9,
              icon: "el-icon-location",
              name: "在线报价",
              url: "/ltl/orderPlace",
            },
            {
              uid: 10,
              icon: "el-icon-location",
              name: "报价列表",
              url: "/ltl/rateList",
            },
            {
              uid: 11,
              icon: "el-icon-location",
              name: "FBA报价",
              url: "/ltl/FBA",
            },
          ],
        },       
        {
          uid: 12,
          icon: "el-icon-news",
          name: "LTL产品管理",
          url: "/ltl/product",
          ischildren: false,
        },          
        {
          uid: 13,
          icon: "el-icon-location-information",
          name: "LTL地址管理",
          url: "/ltl/address",
          ischildren: false,
        },   
        {
          uid: 14,
          icon: "el-icon-wallet",
          name: "我的财务",
          url: "8",
          ischildren: true,
          children: [
            {
              uid: 15,
              icon: "el-icon-location",
              name: "快递消费流水",
              url: "/bill/consumption",
            },
            // {
            //   uid: 15,
            //   icon: "el-icon-location",
            //   name: "快递发票列表",
            //   url: "/bill/invoice",
            // },
            {
              uid: 16,
              icon: "el-icon-location",
              name: "LTL充值管理",
              url: "/ltl/recharge",
            },
            {
              uid: 17,
              icon: "el-icon-location",
              name: "LTL财务明细",
              url: "/ltl/finance",
            },
            {
              uid: 18,
              icon: "el-icon-location",
              name: "LTL发票管理",
              url: "/ltl/invoice",
            },
          ],
        },        
        
        {
          uid: 19,
          icon: "el-icon-close-notification",
          name: "公告列表",
          url: "/ltl/news",
          ischildren: false,
        },          
        {
          uid: 20,
          icon: "el-icon-document-remove",
          name: "我的工单",
          url: "/ltl/workOrder",
          ischildren: false,
        },          
        {
          uid: 21,
          icon: "el-icon-document-remove",
          name: "服务商列表",
          url: "/bill/facilitator/list",
          ischildren: false,
        }, 
        // {
        //   uid: 22,
        //   icon: "el-icon-c-scale-to-original",
        //   name: "计算器",
        //   url: "/bill/calc",
        //   ischildren: false,
        // }, 
        {
          uid: 23,
          icon: "el-icon-setting",
          name: "账号管理",
          url: "10",
          ischildren: true,
          children: [
            // {
            //   uid: 9,
            //   icon: "el-icon-location",
            //   name: "会员中心",
            //   url: "/member",
            // },
            {
              uid: 24,
              icon: "el-icon-location",
              name: "个人信息",
              url: "/bill/info",
            },
            {
              uid: 25,
              icon: "el-icon-location",
              name: "修改密码",
              url: "/bill/password",
            }
          ],
        },
        // {
        //   uid: 22,
        //   icon: "el-icon-user",
        //   name: "个人中心",
        //   url: "/ltl/user",
        //   ischildren: false,
        // }, 
        // {
        //   uid: 17,
        //   icon: "el-icon-tickets",
        //   name: "测试",
        //   url: "/ce",
        //   ischildren: false,
        // } 
      ],
    };
  },
  methods: {
    // 接收顶部切换折叠
    receiveData(e) {
      this.isCollapse = e;
    },
    goToIndex(){
      // this.$router.push({ path: '/'});
      this.$router.push({ path: '/ltl/index'});
    }
  },
};
</script>
<style lang='scss' scoped>
.x-tops {
  height: 100vh;
  background: #409EFF;
  overflow: hidden;
}
.menu-wrapper {
  height: calc(100vh - 50px); /* 减去顶部标题的高度 */
  width: 230px;
  overflow: hidden;
  position: relative;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 230px;
  height: 100%;
  text-align: left;
  overflow-y: scroll;
}
/* 隐藏滚动条 */
.el-menu-vertical-demo::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.el-menu-vertical-demo {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/deep/.el-menu-item.is-active {
  border-left: 4px solid #E6A23C;
  background: rgb(7, 123, 243) !important;
}
/deep/.el-menu-item i,/deep/.el-submenu__title i{
  color: #fff;
  opacity: 0.8;
}
/deep/.el-menu{
  border: none;
}
.logo-img{
  width: 157px;
  height: 50px;
  margin: 0 auto;
  cursor: pointer;
}
.x-title {
  height: 50px;
  width: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  border: 1px solid #409EFF;
  span {
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    font-family: Chinese Quote, -apple-system, BlinkMacSystemFont, Segoe UI,
      PingFang SC, Hiragino Sans GB, Microsoft YaHei, Helvetica Neue, Helvetica,
      Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol;
  }
}
</style>
import Vue from "vue";
import VueRouter from "vue-router";
// import Index from '@/views/official/index'; // 官网首页-框架

import Layout from "@/views/layout/index"; // 后台首页
import Place from "@/views/order/place"; // 国内订单
import FPlace from "@/views/order/fplace"; // 国际订单
import OrderList from "@/views/order/list"; // 订单列表
import Address from "@/views/address/list"; // 地址列表
import Invoice from "@/views/invoice/invoice"; // 地址列表
import FinanceConsumption from "@/views/finance/consumption"; // 消费流水
import FinanceCredit from "@/views/finance/credit"; // 信用流水
import FinanceInvoice from "@/views/finance/invoice"; // 发票列表
import Facilitator from "@/views/facilitator/list"; // 服务商列表
import Member from "@/views/user/member"; // 会员中心
import Info from "@/views/user/info"; // 个人信息
import Password from "@/views/user/password"; // 修改密码
import Calc from "@/views/calc/calc"; // 计算器
import Login from "@/views/login/index"; // 登录
import Register from "@/views/register/index"; // 注册

// ltl
import LTLIndex from "@/views/ltl/index"; // 首页
import LTLOrderList from "@/views/ltl/order/list"; // 订单列表
import LTLOrderPlace from "@/views/ltl/order/place"; // 在线报价
import LTLOrderResult from "@/views/ltl/order/result"; // 报价结果
import LTLOrderOrder from "@/views/ltl/order/order"; // 下单
import LTLRateList from "@/views/ltl/price/rateList"; // 报价列表
import LTLFBA from "@/views/ltl/price/FBA"; // FBA报价
import LTLRecharge from "@/views/ltl/finance/recharge"; // 充值管理
import LTLFinance from "@/views/ltl/finance/finance"; // 充值管理
import LTLInvoice from "@/views/ltl/finance/invoice"; // 发票管理
import LTLProduct from "@/views/ltl/product/list"; // 产品管理
import LTLAddress from "@/views/ltl/address/list"; // 地址管理
import LTLNews from "@/views/ltl/news/news"; // 公告列表
import LTLWorkOrder from "@/views/ltl/work/list"; // 我的工单
import LTLUser from "@/views/ltl/user"; // 个人中心
import ce from "@/views/ltl/ce"; // 测试

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: Login,
    children: [
      {
        path: "/",
        name: "Login",
        component: Login,
        meta: {
          title: "联合快递LTL系统",
        },
      },
    ],
  },
  {
    path: "/layout",
    component: Layout,
    redirect: "/ltl/index",
    children: [
      // {
      //   path: '/order/fplace',
      //   name: 'FPlace',
      //   component: FPlace,
      //   meta:{
      //     title: '国际订单'
      //   }
      // },

      // {
      //   path: '/member',
      //   name: 'Member',
      //   component: Member,
      //   meta:{
      //     title: '会员中心'
      //   }
      // },



      // {
      //   path: '/finance/credit',
      //   name: 'FinanceCredit',
      //   component: FinanceCredit,
      //   meta:{
      //     title: '信用流水'
      //   }
      // },


      {
        path: "/ltl/index",
        name: "LTLIndex",
        component: LTLIndex,
        meta: {
          title: "首页",
        },
      },
      {
        path: "/ltl/orderList",
        name: "LTLOrderList",
        component: LTLOrderList,
        meta: {
          title: "订单列表",
        },
      },
      {
        path: "/ltl/orderPlace",
        name: "LTLOrderPlace",
        component: LTLOrderPlace,
        meta: {
          title: "在线报价",
        },
      },
      {
        path: "/ltl/orderResult",
        name: "LTLOrderResult",
        component: LTLOrderResult,
        meta: {
          title: "报价承运商结果",
        },
      },
      {
        path: "/ltl/orderOrder",
        name: "LTLOrderOrder",
        component: LTLOrderOrder,
        meta: {
          title: "完善订单信息",
        },
      },
      {
        path: "/ltl/rateList",
        name: "LTLRateList",
        component: LTLRateList,
        meta: {
          title: "报价列表",
        },
      },
      {
        path: "/ltl/FBA",
        name: "LTLFBA",
        component: LTLFBA,
        meta: {
          title: "FBA报价",
        },
      },
      {
        path: "/ltl/recharge",
        name: "LTLRecharge",
        component: LTLRecharge,
        meta: {
          title: "LTL充值管理",
        },
      },
      {
        path: "/ltl/finance",
        name: "LTLFinance",
        component: LTLFinance,
        meta: {
          title: "LTL财务明细",
        },
      },
      {
        path: "/ltl/invoice",
        name: "LTLInvoice",
        component: LTLInvoice,
        meta: {
          title: "LTL发票管理",
        },
      },
      {
        path: "/ltl/product",
        name: "LTLProduct",
        component: LTLProduct,
        meta: {
          title: "LTL产品管理",
        },
      },
      {
        path: "/ltl/address",
        name: "LTLAddress",
        component: LTLAddress,
        meta: {
          title: "LTL地址管理",
        },
      },
      {
        path: "/ltl/news",
        name: "LTLNews",
        component: LTLNews,
        meta: {
          title: "公告列表",
        },
      },
      {
        path: "/ltl/workOrder",
        name: "LTLWorkOrder",
        component: LTLWorkOrder,
        meta: {
          title: "我的工单",
        },
      },
      {
        path: "/ltl/user",
        name: "LTLUser",
        component: LTLUser,
        meta: {
          title: "个人中心",
        },
      },
      {
        path: "/ce",
        name: "ce",
        component: ce,
        meta: {
          title: "个人中心",
        },
      },
      {
        path: '/bill/place',
        name: 'Place',
        component: Place,
        meta:{
          title: '新建国内快递面单'
        }
      },
      {
        path: '/bill/fplace',
        name: 'FPlace',
        component: FPlace,
        meta:{
          title: '新建国外快递面单'
        }
      },
      {
        path: '/bill/list',
        name: 'OrderList',
        component: OrderList,
        meta:{
          title: '快递订单管理'
        }
      },
      {
        path: '/bill/address',
        name: 'Address',
        component: Address,
        meta:{
          title: '快递地址管理'
        }
      },
      {
        path: '/bill/invoice',
        name: 'Invoice',
        component: Invoice,
        meta:{
          title: '发票列表'
        }
      },
      {
        path: '/bill/consumption',
        name: 'FinanceConsumption',
        component: FinanceConsumption,
        meta:{
          title: '快递消费流水'
        }
      },
      {
        path: '/bill/invoice',
        name: 'FinanceInvoice',
        component: FinanceInvoice,
        meta:{
          title: '快递发票列表'
        }
      },
      {
        path: '/bill/facilitator/list',
        name: 'Facilitator',
        component: Facilitator,
        meta:{
          title: '服务商列表'
        }
      },
      {
        path: '/bill/info',
        name: 'Info',
        component: Info,
        meta:{
          title: '个人信息'
        }
      },
      {
        path: '/bill/password',
        name: 'Password',
        component: Password,
        meta:{
          title: '修改密码'
        }
      },
      {
        path: '/bill/calc',
        name: 'Calc',
        component: Calc,
        meta:{
          title: '计算器'
        }
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      title: "登录",
    },
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: {
      title: "注册",
    },
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;

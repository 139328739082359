<template>
  <div class="">
    <el-card class="box-card">
      <!-- <div class="search-form" style="text-align: left">
        <el-form ref="searchForm" :inline="true" :model="searchForm" label-width="100px" label-position="left"
          class="demo-form-inline" size="small">
          <el-form-item label="	地址类型">
            <el-select v-model="searchForm.type" placeholder="请选择地址类型">
              <el-option label="发件人地址" :value="1"></el-option>
              <el-option label="收件人地址" :value="2"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item>
            <el-button icon="el-icon-search" type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button icon="el-icon-refresh-right" type="primary" size="small" @click="resetSearch">重置</el-button>
          </el-form-item>
        </el-form>
      </div> -->
      <el-table :data="dataList" border size="small" height="600">
        <el-table-column type="index" :index="indexMethod" label="序号" width="60"></el-table-column>
        <el-table-column prop="order_sn" label="订单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="platform_order_sn" label="运单号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="createtime" label="创建时间" show-overflow-tooltip></el-table-column>
        <el-table-column prop="action" label="操作" width="140">
          <template slot-scope="scope">
            <div>
              <span style="color: #409eff; cursor: pointer" @click="handleDetail(scope.row)">查看发票</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagization-box">
        <CustomPagization
          :background="true"
          :currentPage="current"
          :total="total"
          :pageSize="size"
          @current-change="currentChange"
        />
      </div>
    </el-card>

    <!-- 表单弹窗 -->
    <el-dialog
      title="订单发票"
      append-to-body
      :visible.sync="detailVisible"
      width="1200px"
      :before-close="closeDialog"
    >
      <Detail
        ref="formModal"
        :id="id"
        v-if="detailVisible"
        append-to-body
      />
    </el-dialog>
  </div>
</template>

<script>
import { invoiceList } from '@/api/invoice'
import Detail from './detail.vue';
export default {
  components: {
    Detail
  },
  data() {
    return {
      detailVisible: false,
      id: '',
      searchForm: {},
      current: 1,
      size: 20,
      total: 1,
      dataList: [],
    };
  },
  mounted(){
    this.getAddress()
  },
  methods: {
    // 获取列表
    getAddress(){
      invoiceList({
        user_code:JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        page: this.current,
        limit: this.size,
        ...this.searchForm
      }).then(res => {
        if(res.code == 1){
          this.dataList = []
          this.dataList = res.data.list
          this.total = res.data.total
        }
      })
    },
    // 关闭弹框
    closeDialog() {
      this.id = '';
      this.detailVisible = false;
    },
    //视频监控站点修改
    handleDetail(item) {
      this.id = item.id
      this.detailVisible = true
    },
    // table首列序号
    indexMethod(index) {
      return (this.current - 1) * this.size + index + 1;
    },
    // 页数变化
    currentChange(current) {
      this.current = current;
      this.getAddress();
    },
    // 重置筛选
    resetSearch() {
      this.$refs["searchForm"].resetFields();
      this.searchForm = {}
      this.value1 = []
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.handleSearch();
    },
    // 去筛选
    handleSearch() {
      this.current = 1;
      this.total = 1;
      this.dataList = [];
      this.getAddress();
    },
  },
};
</script>
<style lang="scss" scoped>
.pagization-box {
  overflow: hidden;
  margin-top: 10px;

  .el-pagination {
    float: right;
  }
}
/deep/ {
  .el-table {
    thead {
      color: #fff;
      font-weight: 500;
      background: #F5F7FA;
      color: #606266;
      & th {
        background-color: transparent;
      }
      & tr {
        background-color: transparent;
      }
    }
  }
}
</style>

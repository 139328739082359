<template>
  <div class="place">
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="90px"
      class="demo-ruleForm"
      size="small"
    >
      <!-- 发件人 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="12">
          <div class="place-title">
            发货信息
            <!-- <el-button type="warning" size='small' v-for="(item,index) in send_oneself_list" :key="index" @click="selectOneself(item.addr)">{{ item.name }}</el-button> -->
          </div>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-select
            v-model="sendSelectValue"
            placeholder="选择发件地址"
            filterable
            size="small"
            style="width: 180px; margin-left: 10px"
            @change="selectSendAddr"
          >
            <el-option
              v-for="(item, index) in send_list"
              :key="index"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-button type="primary" @click="selectAddress(1)" size='small' class="add-select-btn">选择收件人</el-button> -->
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="姓名" prop="send_data.realname">
            <el-input v-model="ruleForm.send_data.realname" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="公司" prop="send_data.company">
            <el-input v-model="ruleForm.send_data.company" placeholder="请输入公司"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="国家" prop="send_data.country">
            <el-select
              v-model="ruleForm.send_data.country"
              filterable
              placeholder="请选择省国家"
              style="width: 100%"
              @change="handleSendChange"
            >
              <el-option
                v-for="(item, index) in countryList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="州/省" prop="send_data.province">
            <el-select
              v-model="ruleForm.send_data.province"
              filterable
              placeholder="请选择省州/省"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in sendProvinceList"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="城市" prop="send_data.city">
            <el-input v-model="ruleForm.send_data.city" placeholder="请输入城市"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="邮编" prop="send_data.zcode">
            <el-input
              v-model="ruleForm.send_data.zcode"
              placeholder="请输入邮编"
              @blur="getCityInfo(1)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址1" prop="send_data.address_one">
            <el-input v-model="ruleForm.send_data.address_one" placeholder="请输入地址1"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址2" prop="send_data.address_two">
            <el-input v-model="ruleForm.send_data.address_two" placeholder="请输入地址2"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="电话" prop="send_data.mobile">
            <el-input v-model="ruleForm.send_data.mobile" placeholder="请输入电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="邮箱" prop="send_data.email">
            <el-input v-model="ruleForm.send_data.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="warning" @click="clearAddress(1)" size="small" class="add-select-btn"
            >清除</el-button
          >
          <el-button
            type="success"
            @click="addAddress('ruleForm', 1)"
            size="small"
            class="add-select-btn"
            >保存到地址簿</el-button
          >
        </el-col>
      </el-row>
      <!-- 收件人 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="12">
          <div class="place-title">收货信息</div>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-select
            v-model="FbaValue"
            placeholder="选择FBA地址"
            filterable
            size="small"
            style="width: 180px; margin-left: 10px"
            @change="selectFBAAddr"
          >
            <el-option
              v-for="(item, index) in fbaLists"
              :key="index"
              :label="item.fba_code"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
            v-model="receiveSelectValue"
            filterable
            placeholder="选择收件地址"
            size="small"
            style="width: 180px; margin-left: 10px"
            @change="selectReceiveAddr"
          >
            <el-option
              v-for="(item, index) in receive_list"
              :key="index"
              :label="item.realname"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <!-- <el-button type="primary" @click="selectAddress(2)" size='small' class="add-select-btn">选择收件人</el-button> -->
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="姓名" prop="receive_data.realname">
            <el-input v-model="ruleForm.receive_data.realname" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="公司" prop="receive_data.company">
            <el-input v-model="ruleForm.receive_data.company" placeholder="请输入公司"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="国家" prop="receive_data.country">
            <el-select
              v-model="ruleForm.receive_data.country"
              filterable
              placeholder="请选择省国家"
              style="width: 100%"
              @change="handleReceiveChange"
            >
              <el-option
                v-for="(item, index) in countryList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="州/省" prop="receive_data.province">
            <el-select
              v-model="ruleForm.receive_data.province"
              filterable
              placeholder="请选择省州/省"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in receiveProvinceList"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="城市" prop="receive_data.city">
            <el-input v-model="ruleForm.receive_data.city" placeholder="请输入城市"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="邮编" prop="receive_data.zcode">
            <el-input
              v-model="ruleForm.receive_data.zcode"
              placeholder="请输入邮编"
              @blur="getCityInfo(2)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址1" prop="receive_data.address_one">
            <el-input
              v-model="ruleForm.receive_data.address_one"
              placeholder="请输入地址1"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址2" prop="receive_data.address_two">
            <el-input
              v-model="ruleForm.receive_data.address_two"
              placeholder="请输入地址2"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="电话" prop="receive_data.mobile">
            <el-input v-model="ruleForm.receive_data.mobile" placeholder="请输入电话"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="邮箱" prop="receive_data.email">
            <el-input v-model="ruleForm.receive_data.email" placeholder="请输入邮箱"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="warning" @click="clearAddress(2)" size="small" class="add-select-btn"
            >清除</el-button
          >
          <el-button
            type="warning"
            @click="addAddress('ruleForm', 3)"
            size="small"
            class="add-select-btn"
            >校验地址</el-button
          >
          <el-button
            type="success"
            @click="addAddress('ruleForm', 2)"
            size="small"
            class="add-select-btn"
            >保存到地址簿</el-button
          >
        </el-col>
      </el-row>
      <!-- 货运/商品 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="12">
          <div class="place-title">货运/商品</div>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <!-- <el-col :span="6">
          <el-form-item label="订单号" prop="goods_info.hb_order_sn">
            <el-input v-model="ruleForm.goods_info.hb_order_sn" placeholder="请输入订单号"></el-input>
          </el-form-item>
        </el-col> -->
        <el-col :span="6">
          <el-form-item label="物流渠道" prop="goods_info.pservice_type">
            <el-select
              v-model="ruleForm.goods_info.pservice_type"
              placeholder="请选择物流渠道"
              style="width: 100%"
              @change="changePservice"
            >
              <el-option
                v-for="(item, index) in channelList"
                :key="index"
                :label="item.name"
                :value="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="电商订单号" prop="goods_info.hb_order_sn">
            <el-input
              v-model="ruleForm.goods_info.hb_order_sn"
              placeholder="请输入电商订单号"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="面单备注" prop="goods_info.order_remark">
            <el-input
              v-model="ruleForm.goods_info.order_remark"
              placeholder="请输入面单备注"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10">
        <el-col :span="6">
          <el-form-item label="备注留言" prop="goods_info.note">
            <el-input v-model="ruleForm.goods_info.note" placeholder="请输入备注留言"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="发货日期" prop="goods_info.shipment_date">
            <el-date-picker
              v-model="ruleForm.goods_info.shipment_date"
              style="width: 100%"
              type="date"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              placeholder="请选择发货日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="是否签名" prop="goods_info.is_sign">
            <el-select
              v-model="ruleForm.goods_info.is_sign"
              placeholder="请选择是否签名"
              style="width: 100%"
            >
              <el-option label="不签名" :value="0"></el-option>
              <el-option label="成人签名传" :value="1"></el-option>
              <el-option label="普通签名传" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 预约取件 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="12">
          <div class="place-title">
            <el-checkbox
              v-model="is_qus"
              @change="handleCheck"
              style="margin-right: 5px"
            ></el-checkbox
            >预约取件
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="is_qus">
        <el-col :span="6">
          <el-form-item label="姓名" prop="q_data.realname">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.realname"
              placeholder="请输入预约人姓名"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="公司" prop="q_data.company">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.company"
              placeholder="请输入预约公司"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="国家" prop="q_data.country">
            <el-select
              :disabled="!is_qus"
              filterable
              v-model="ruleForm.q_data.country"
              placeholder="请选择预约国家"
              style="width: 100%"
              @change="handleQChange"
            >
              <el-option
                v-for="(item, index) in countryList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="州/省" prop="q_data.province">
            <el-select
              :disabled="!is_qus"
              filterable
              v-model="ruleForm.q_data.province"
              placeholder="请选择预约州/省"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in qProvinceList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="is_qus">
        <el-col :span="6">
          <el-form-item label="城市" prop="q_data.city">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.city"
              placeholder="请输入预约城市"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="邮编" prop="q_data.zcode">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.zcode"
              placeholder="请输入预约邮编"
              @blur="getCityInfo(3)"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址1" prop="q_data.address_one">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.address_one"
              placeholder="请输入预约地址1"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="地址2" prop="q_data.address_two">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.address_two"
              placeholder="请输入预约地址2"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="is_qus">
        <el-col :span="6">
          <el-form-item label="电话" prop="q_data.mobile">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_data.mobile"
              placeholder="请输入预约电话"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" style="text-align: right">
          <el-button type="warning" @click="clearAddress(3)" size="small" class="add-select-btn"
            >清除</el-button
          >
        </el-col>
      </el-row>
      <!-- 封装信息 -->
      <el-row :gutter="10" class="border-col" v-if="is_qus">
        <el-col :span="12">
          <div class="place-title">封装信息</div>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="is_qus">
        <el-col :span="6">
          <el-form-item label="包裹类型" prop="q_package.q_package_type">
            <el-select
              :disabled="!is_qus"
              v-model="ruleForm.q_package.q_package_type"
              placeholder="请选择包裹类型"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in qPackageTypeList"
                :key="index"
                :label="item.label"
                :value="item.label"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="包裹总数" prop="q_package.package_all_num">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_package.package_all_num"
              placeholder="请输入包裹总数"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="总重量" prop="q_package.all_weight">
            <el-input
              :disabled="!is_qus"
              v-model="ruleForm.q_package.all_weight"
              placeholder="请输入总重量"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="取货日期" prop="q_package.q_date">
            <el-date-picker
              :disabled="!is_qus"
              v-model="ruleForm.q_package.q_date"
              type="date"
              style="width: 100%"
              placeholder="请输入取货日期"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="10" v-if="is_qus">
        <el-col :span="6">
          <el-form-item label="准备时间" prop="q_package.q_time">
            <el-select
              :disabled="!is_qus"
              v-model="ruleForm.q_package.q_time"
              placeholder="请选择准备时间"
              style="width: 100%"
              @change="changeTime"
            >
              <el-option
                v-for="(item, index) in qtimelist"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="最晚时间" prop="q_package.q_endtime">
            <el-select
              :disabled="!is_qus"
              v-model="ruleForm.q_package.q_endtime"
              placeholder="请选择最晚可用时间"
              style="width: 100%"
            >
              <el-option
                v-for="(item, index) in qendtimelist"
                :key="index"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="特殊说明" prop="q_package.package_remark">
            <el-input
              :disabled="!is_qus"
              type="textarea"
              :rows="2"
              v-model="ruleForm.q_package.package_remark"
              placeholder="请输入特殊说明"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- 包裹 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="24">
          <div class="place-title" style="display: flex">
            包裹信息
            <el-radio-group v-model="pa_unit" size="small" style="margin-left: 10px">
              <el-radio-button label="磅/英寸" :value="1"></el-radio-button>
              <el-radio-button label="千克/厘米" :value="2"></el-radio-button>
            </el-radio-group>
            <span class="tips">提示：包裹重量和尺寸会直接影响运输费用</span>
          </div>
        </el-col>
      </el-row>
      <div class="parcel-item" v-for="(item, index) in ruleForm.package_list" :key="index">
        <!-- <el-col :span="24"> -->
        <div class="parcel-top">
          <span class="parcel-title">{{ index + 1 }}-包裹信息</span>
          <i
            style="font-size: 20px; margin: 0 10px; color: #409dfe; cursor: pointer"
            class="el-icon-circle-plus-outline"
            @click="addParcel"
          ></i>
          <i
            style="font-size: 20px; color: #f56c6c; cursor: pointer; margin-right: 10px"
            class="el-icon-remove-outline"
            v-if="ruleForm.package_list.length > 1"
            @click="delParcel(index)"
          ></i>
          <!-- <i style="font-size: 20px;color:#67C23A;cursor: pointer;" class="el-icon-document-copy" @click="copyParcel(index,item)"></i> -->
        </div>
        <!-- </el-col> -->
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item label="包裹类型" label-width="110px">
              <el-select v-model="item.pa_type" style="width: 100%">
                <el-option
                  :label="item.label"
                  :value="item.label"
                  v-for="(item, index) in pa_type_list"
                  :key="index"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="Sku"
              label-width="110px"
              :prop="`package_list[${index}][Sku]`"
              :rules="rules.Sku"
            >
              <el-input v-model="item.Sku"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              label="包裹数量"
              label-width="110px"
              :prop="`package_list[${index}][pa_num]`"
              :rules="rules.pa_num"
            >
              <div class="parcel-lwh">
                <el-input-number
                  v-model="item.pa_num"
                  :step="1"
                  style="width: 180px"
                ></el-input-number>
                <el-button
                  type="warning"
                  size="small"
                  @click="copyParcel(index, item)"
                  style="margin-left: 10px"
                  >复制包裹</el-button
                >
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="10">
          <el-col :span="8">
            <el-form-item
              :label="pa_unit == '磅/英寸' ? '长*宽*高(in)' : '长*宽*高(cm)'"
              label-width="110px"
              :prop="`package_list[${index}][pa_length]`"
              :rules="item.pa_type == 'Your Packaging' ? rules.pa_length : []"
            >
              <div class="parcel-lwh">
                <el-input
                  type="number"
                  :controls="false"
                  v-model="item.pa_length"
                  style="width: 100px"
                ></el-input
                >*
                <el-input
                  type="number"
                  :controls="false"
                  v-model="item.pa_width"
                  style="width: 100px"
                ></el-input
                >*
                <el-input
                  type="number"
                  :controls="false"
                  v-model="item.pa_height"
                  style="width: 100px"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :label="pa_unit == '磅/英寸' ? '重量(lb)' : '重量(KG)'"
              label-width="110px"
              :prop="`package_list[${index}][pa_lb]`"
              :rules="rules.pa_lb"
            >
              <div class="parcel-lwh">
                <el-input
                  type="number"
                  :controls="false"
                  v-model="item.pa_lb"
                  style="width: 100%"
                ></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申报价值" label-width="110px">
              <div class="parcel-lwh">
                <el-input v-model="item.insurance" style="width: 160px">
                  <template slot="prepend">$</template>
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <!-- 申报项目 -->
      <el-row :gutter="10" class="border-col">
        <el-col :span="24" style="display: flex">
          <div class="place-title" style="display: flex">申报项目</div>
          <div class="parcel-top">
            <i
              style="font-size: 20px; color: #409dfe; cursor: pointer"
              class="el-icon-circle-plus-outline"
              @click.stop="addProject"
            ></i>
          </div>
        </el-col>
      </el-row>
      <div class="parcel-item1" v-for="(item, index) in ruleForm.project_list" :key="index + 1">
        <el-row :gutter="10">
          <el-col :span="4">
            <div class="parcel-item-label">海关编码</div>
            <el-form-item label="" label-width="0px" :prop="`project_list[${index}][code]`">
              <el-input v-model="item.code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div class="parcel-item-label">计量单位</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][unit_of]`"
              :rules="rules.unit_of"
            >
              <el-select
                v-model="item.unit_of"
                filterable
                placeholder="请选择"
                style="width: 100%"
              >
                <el-option
                  v-for="(item, index) in unitList"
                  :key="index"
                  :label="item.label"
                  :value="item.label"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div class="parcel-item-label">商品价值</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][unit_price]`"
              :rules="rules.unit_price"
            >
              <div class="parcel-lwh">
                <el-input v-model="item.unit_price" :step="1" style="width: 180px"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <div class="parcel-item-label">产品描述</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][product_info]`"
              :rules="rules.product_info"
            >
              <div class="parcel-lwh">
                <el-input v-model="item.product_info" :step="1" style="width: 100%"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="2">
            <div class="parcel-item-label">包装种类</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][pack_kind]`"
              :rules="rules.pack_kind"
            >
              <div class="parcel-lwh">
                <el-input v-model="item.pack_kind" :step="1" style="width: 180px"></el-input>
              </div>
            </el-form-item>
          </el-col> -->
          <el-col :span="2">
            <div class="parcel-item-label">数量</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][num]`"
              :rules="rules.num"
            >
              <div class="parcel-lwh">
                <el-input v-model="item.num" :step="1" style="width: 180px"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <div class="parcel-item-label">净重</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][suttle]`"
              :rules="rules.suttle"
            >
              <div class="parcel-lwh">
                <el-input v-model="item.suttle" :step="1" style="width: 180px"></el-input>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <div class="parcel-item-label">产地</div>
            <el-form-item
              label=""
              label-width="0px"
              :prop="`project_list[${index}][chandi]`"
              :rules="rules.chandi"
            >
              <div class="parcel-lwh">
                <el-select
                  filterable
                  v-model="item.chandi"
                  placeholder="请选择"
                  style="width: 180px"
                >
                  <el-option
                    v-for="(item, index) in countryList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :span="1" v-if="ruleForm.project_list.length > 1">
            <i
              style="font-size: 20px; color: #f56c6c; cursor: pointer; margin-right: 10px"
              class="el-icon-delete"
              @click.stop="delProject(index)"
            ></i>
          </el-col>
        </el-row>
      </div>

      <!-- 按钮 -->
      <el-row :gutter="10">
        <el-col :span="24" class="bttn">
          <el-button
            type="warning"
            size="small"
            @click="handleFree('ruleForm')"
            :loading="freeLoading"
            >预测费率</el-button
          >
          <div class="free-rate" v-if="ratesMoney">
            费用率预测：<span>${{ ratesMoney.toFixed(2) }} 重量：{{ ratesWeight }}</span>
          </div>
          <el-button
            type="success"
            size="small"
            @click="submitDraftForm('ruleForm')"
            :loading="draftLoading"
            >保存草稿</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="submitForm('ruleForm')"
            :loading="orderLoading"
            >生成物流运单</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="storeForm('ruleForm')"
            :loading="storeLoading"
            >门店发货</el-button
          >
          <el-button @click="resetForm('ruleForm')" size="small">重置</el-button>
        </el-col>
      </el-row>
    </el-form>
    <!-- 表单弹窗 -->
    <!-- <el-dialog
      title="选择地址"
      append-to-body
      :visible.sync="formVisible"
      width="1000px"
      :before-close="closeDialog"
    >
      <AddressList
        ref="formModal"
        :isType="isType"
        v-if="formVisible"
        @ok="editSuccess"
        append-to-body
      />
    </el-dialog> -->
    <!-- 面单 -->
    <el-dialog
      title="预测费用"
      append-to-body
      :visible.sync="freeVisible"
      width="900px"
      :before-close="closeFreeDialog"
    >
      <Free v-if="freeVisible" :freeInfo="freeInfo" append-to-body @getRatesInfo="getRatesInfo" />
    </el-dialog>
    <!-- 校验地址后的提示 -->
    <el-dialog
      title="校验结果"
      append-to-body
      :visible.sync="checkVisible"
      width="400px"
      class="check-dialog"
      :before-close="closeCheckDialog"
    >
      <Check v-if="checkVisible" append-to-body @closeCheck="closeCheck" :checkInfo="checkInfo" />
    </el-dialog>
  </div>
</template>

<script>
import { address, addressAdd } from "@/api/address";
import {
  createorder_new,
  fbaList,
  rates_new,
  orderInfo,
  saveOrder_new,
  cityInfo,
  storeOrder_new,
} from "@/api/order";
import { fCity, serviceNameList, checkAddress, serviceListnew } from "@/api/common";
// import AddressList from "../address/list";
import Free from "./components/free.vue";
import Check from "./components/check.vue";
import {
  validatenull,
  transformCountryCity,
  provinceListValue,
  findTimeIndex,
} from "@/common/common.js";
export default {
  components: {
    // AddressList,
    Free,
    Check,
  },
  props: {
    record: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      sendSelectValue: [], // /选择发件地址城市
      receiveSelectValue: [], // 选择收件地址城市
      pa_unit: "磅/英寸", // 单位 1-lb/in 2-kg/cm
      FbaValue: "",
      // formVisible: false,
      freeVisible: false,
      freeInfo: {}, // 预测费率信息
      checkVisible: false,
      checkInfo: {}, //校验地址
      ratesMoney: "", // 预测费用
      ratesWeight: "", // 预测重量
      freeLoading: false, // 预测按钮
      draftLoading: false, // 保存草稿按钮
      orderLoading: false, // 生成物流运单按钮
      storeLoading: false, // 门店发货
      pa_type_list: [
        {
          label: "Your 10kg Box",
        },
        {
          label: "Your 25kg Box",
        },
        {
          label: "Your Envelope",
        },
        {
          label: "Your Extra Large Box",
        },
        {
          label: "Your Large Box",
        },
        {
          label: "Your Medium Box",
        },
        {
          label: "Your Pak",
        },
        {
          label: "Your Small Box",
        },
        {
          label: "Your Tube",
        },
        {
          label: "Your Packaging",
        },
      ], // 包裹类型
      qtimelist: [
        "7:00 am",
        "7:30 am",
        "8:00 am",
        "8:30 am",
        "9:00 am",
        "9:30 am",
        "10:00 am",
        "10:30 am",
        "11:00 am",
        "11:30 am",
        "12:00 pm",
        "12:30 pm",
        "1:00 pm",
        "1:30 pm",
        "2:00 pm",
        "2:30 pm",
        "3:00 pm",
        "3:30 pm",
        "4:00 pm",
        "4:30 pm",
        "5:00 pm",
        "5:30 pm",
        "6:00 pm",
        "6:30 pm",
        "7:00 pm",
        "7:30 pm",
        "8:00 pm",
        "8:30 pm",
        "9:00 pm",
      ],
      qendtimelist: [],
      ruleForm: {
        goods_info: {
          is_sign: 0, // 默认为0
          service_type: "", //服务商
          USPSEndorsement: 1, //1需要退货服务   5不需要服务
        }, // 服务商信息
        package_list: [
          {
            Sku: "001", //Sku
            insurance: 0, //保险金额（默认0）
            pa_height: '', //高度
            pa_lb: "", //重量
            pa_length: '', //长度
            pa_num: 1, //数量
            pa_type: "Your Packaging", //包裹类型 字段必须存在 值可为空
            pa_width: '', //宽度
          },
        ],
        project_list: [
          {
            code: "",
            unit_of: "",
            unit_price: "",
            product_info: "",
            pack_kind: "",
            num: "",
            suttle: "",
            chandi: "",
          },
        ], //申报项目
        // 包裹列表
        receive_data: {
          company: "",
          address_two: "",
          email: "",
          country: "United States",
        }, // 收件人信息
        send_data: {
          company: "",
          address_two: "",
          email: "",
          country: "United States",
        }, // 发件人信息
        is_qu: 0, //是否安排取货0-否1-是
        q_data: {
          company: "",
          address_two: "",
        },
        q_package: {},
      },
      is_qus: false,
      rules: {
        "send_data.realname": [{ required: true, message: "请输入发件人姓名", trigger: "blur" }],
        "send_data.country": [{ required: true, message: "请选择发件人国家", trigger: "change" }],
        "send_data.province": [{ required: true, message: "请选择发件人州/省", trigger: "change" }],
        "send_data.city": [{ required: true, message: "请输入发件人城市", trigger: "blur" }],
        "send_data.zcode": [{ required: true, message: "请输入发件人邮编", trigger: "blur" }],
        "send_data.address_one": [
          { required: true, message: "请输入发件人地址1", trigger: "blur" },
        ],
        "send_data.mobile": [{ required: true, message: "请输入发件人电话", trigger: "blur" }],

        "receive_data.realname": [{ required: true, message: "请输入收件人姓名", trigger: "blur" }],
        "receive_data.country": [
          { required: true, message: "请选择收件人国家", trigger: "change" },
        ],
        "receive_data.province": [
          { required: true, message: "请选择收件人州/省", trigger: "change" },
        ],
        "receive_data.city": [{ required: true, message: "请输入收件人城市", trigger: "blur" }],
        "receive_data.zcode": [{ required: true, message: "请输入收件人邮编", trigger: "blur" }],
        "receive_data.address_one": [
          { required: true, message: "请输入收件人地址1", trigger: "blur" },
        ],
        "receive_data.mobile": [{ required: true, message: "请输入收件人电话", trigger: "blur" }],

        "goods_info.pservice_type": [
          { required: true, message: "请选择物流渠道", trigger: "change" },
        ],

        // 预约取货信息
        "q_data.realname": [{ required: true, message: "请输入预约人姓名", trigger: "blur" }],
        "q_data.country": [{ required: true, message: "请选择预约国家", trigger: "change" }],
        "q_data.province": [{ required: true, message: "请选择预约州/省", trigger: "change" }],
        "q_data.city": [{ required: true, message: "请输入预约城市", trigger: "blur" }],
        "q_data.zcode": [{ required: true, message: "请输入预约邮编", trigger: "blur" }],
        "q_data.address_one": [{ required: true, message: "请输入预约地址1", trigger: "blur" }],
        "q_data.mobile": [{ required: true, message: "请输入预约电话", trigger: "blur" }],
        // 封装信息
        "q_package.q_package_type": [
          { required: true, message: "请选择包裹类型", trigger: "change" },
        ],
        "q_package.package_all_num": [
          { required: true, message: "请输入包裹总数", trigger: "blur" },
        ],
        "q_package.all_weight": [{ required: true, message: "请输入总重量", trigger: "blur" }],
        "q_package.q_date": [{ required: true, message: "请选择取货日期", trigger: "change" }],
        "q_package.q_time": [{ required: true, message: "请输入准备时间", trigger: "change" }],
        "q_package.q_endtime": [
          { required: true, message: "请输入最晚可用时间", trigger: "change" },
        ],

        code: [{ required: true, message: "请输入海关编码", trigger: "blur" }],
        unit_of: [{ required: true, message: "请输入计量单位", trigger: "blur" }],
        unit_price: [{ required: true, message: "请输入商品价值", trigger: "blur" }],
        product_info: [{ required: true, message: "请输入产品描述", trigger: "blur" }],
        pack_kind: [{ required: true, message: "请输入包装种类", trigger: "blur" }],
        num: [{ required: true, message: "请输入数量", trigger: "blur" }],
        suttle: [{ required: true, message: "请输入净重", trigger: "blur" }],
        chandi: [{ required: true, message: "请输入产地", trigger: "blur" }],

        Sku: [{ required: true, message: "请输入Sku", trigger: "blur" }],
        pa_num: [{ required: true, message: "请输入数量", trigger: "blur" }],
        pa_lb: [{ required: true, message: "请输入重量", trigger: "blur" }],
        pa_length: [{ required: true, message: "请输入长宽高", trigger: "blur" }],
      },
      countryList: [], // 国家-城市
      sendProvinceList: [], // 发件人省
      receiveProvinceList: [], // 发件人省
      qProvinceList: [], // 预约取件省
      qPackageTypeList: [
        {
          label: "FedEx Ground",
        },
        {
          label: "FedEx Express",
        },
      ], //预约包裹类型
      channelList: [], // 物流渠道
      fbaLists: [], // FBA地址
      receive_list: [], //收件人地址列表
      send_list: [], //发件人地址列表
      send_oneself_list: [
        {
          name: "CTSGCOCA",
          addr: {
            address_one: "2300 S EASTERN AVE", //地址
            city: "Commerce", //城市
            company: "Operation Manager", //公司
            country: "United States", //国家
            email: "jessiew@ctsglobalsolutions.com", //电子邮箱，比如 example@qq.com
            mobile: "(626) 693-8893", //手机号码
            province: "CA", //州/省
            realname: "Jessie Wang", //姓名
            zcode: "90040-1431", //邮编
          },
        },
        {
          name: "CTSGSGNY",
          addr: {
            address_one: "14709 182ND ST", //地址
            city: "Springfield Gardens", //城市
            company: "Operation Manager", //公司
            country: "United States", //国家
            email: "ront@ctsglobalsolutions.com", //电子邮箱，比如 example@qq.com
            mobile: "(626) 709-3199", //手机号码
            province: "NY", //州/省
            realname: "Ron Tong", //姓名
            zcode: "11413-4034", //邮编
          },
        },
      ], //自定义菜单
      id: "",
      unitList: [
        { label: "Most used" },
        { label: "pieces" },
        { label: "each" },
        { label: "box" },
        { label: "number" },
        { label: "no unit required" },
        { label: "Basic" },
        { label: "barrels" },
        { label: "crate" },
        { label: "dose" },
        { label: "dozen" },
        { label: "dozen pairs" },
        { label: "dozen pieces" },
        { label: "gross" },
        { label: "hundred" },
        { label: "items" },
        { label: "leaf" },
        { label: "one hundred leaves" },
        { label: "pack (cards)" },
        { label: "packs" },
        { label: "pair" },
        { label: "rolls" },
        { label: "running bales" },
        { label: "sets" },
        { label: "square" },
        { label: "thousand" },
        { label: "units" },
        { label: "yarn" },
        { label: "Imperial" },
        { label: "ounces" },
        { label: "pounds" },
        { label: "short tons" },
        { label: "feet" },
        { label: "yards" },
        { label: "linear feet" },
        { label: "linear yards" },
        { label: "square inches" },
        { label: "square feet" },
        { label: "square yards" },
        { label: "hundred square inches" },
        { label: "board feet" },
        { label: "gallon" },
        { label: "cubic feet" },
        { label: "cubic yards" },
        { label: "Minerals and gemstones" },
        { label: "carats" },
        { label: "jewels" },
        { label: "quarts" },
      ],
    };
  },
  mounted() {
    if (!validatenull(this.record)) {
      this.id = this.record.order_id;
      this.getOrderInfo();
    }
  },
  created() {
    // this.getDefaultAddress()
    this.getFbaList(); // 获取FBA地址
    this.getCity(); // 获取国家城市
    this.getServiceNameList(); // 获取物流渠道
    this.getAddress();
    // this.getServiceListnew()
    //
    // this.query.blockId = this.$route.query.rates;
    // this.query.blockId = this.$route.query.pages;
    if (this.$route.query.rates) {
    }
    if (this.$route.query.form) {
      let form = JSON.parse(this.$route.query.form);
      (this.ruleForm.send_data.zcode = form.start_zip_code), //发件人邮编
        (this.ruleForm.send_data.country = form.start_country), //发件人国家
        (this.ruleForm.receive_data.zcode = form.destination_zip_code), //收件人邮编
        (this.ruleForm.receive_data.country = form.destination_country), //收件人国家
        (this.ruleForm.send_data.province = "CA");
      // this.getCityInfo(1)
      // this.getCityInfo(2)

      this.ruleForm.send_data.province = form.send_data_province;
      this.ruleForm.send_data.city = form.send_data_city;
      this.sendProvinceList = provinceListValue(this.countryList, "United States");

      this.ruleForm.receive_data.province = form.receive_data_province;
      this.ruleForm.receive_data.city = form.receive_data_city;

      this.receiveProvinceList = provinceListValue(this.countryList, "United States");

      this.pa_unit = form.pa_unit == "lb/in" ? "磅/英寸" : "千克/厘米";
      let package_list = [];
      package_list.push({
        Sku: "001", //Sku
        insurance: 0, //保险金额（默认0）
        pa_height: form.pa_height, //高度
        pa_lb: form.pa_lb, //重量
        pa_length: form.pa_length, //长度
        pa_num: form.pack_num, //数量
        pa_type: "Your Packaging", //包裹类型 字段必须存在 值可为空
        pa_width: form.pa_width, //宽度
      });
      this.ruleForm.package_list = package_list;

      let project_list = [];
      project_list.push({
        code: form.code,
        unit_of: form.unit_of,
        unit_price: form.unit_price,
        product_info: form.product_info,
        pack_kind: form.pack_kind,
        num: form.num,
        suttle: form.suttle,
        chandi: form.chandi,
      });
      this.ruleForm.project_list = project_list;
    }
  },
  methods: {
    // 获取服务标准
    // getServiceListnew(){
    //   serviceListnew({
    //     user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
    //   }).then(res => {
    //     this.qPackageTypeList = res.data.map(item => {
    //       return {
    //         label:item
    //       }
    //     })
    //   })
    // },
    // 切换时间
    changeTime(e) {
      this.qendtimelist = findTimeIndex(this.qtimelist, e);
    },
    // 预约取件
    handleCheck(value) {
      this.ruleForm.is_qu = value ? 1 : 0;
    },
    // 清除地址
    clearAddress(num) {
      if (num == 1) {
        this.ruleForm.send_data = {
          company: "",
          address_two: "",
          email: "",
        };
        this.sendSelectValue = [];
        this.sendProvinceList = [];
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate([
            "send_data.realname",
            "send_data.country",
            "send_data.province",
            "send_data.city",
            "send_data.zcode",
            "send_data.address_one",
            "send_data.mobile",
          ]);
        });
      } else if (num == 2) {
        this.ruleForm.receive_data = {
          company: "",
          address_two: "",
          email: "",
        };
        this.FbaValue = "";
        this.receiveProvinceList = [];
        this.receiveSelectValue = [];
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate([
            "receive_data.realname",
            "receive_data.country",
            "receive_data.province",
            "receive_data.city",
            "receive_data.zcode",
            "receive_data.address_one",
            "receive_data.mobile",
          ]);
        });
      } else if (num == 3) {
        this.ruleForm.q_data = {
          company: "",
          address_two: "",
          email: "",
        };
        this.qProvinceList = [];
        this.$nextTick(() => {
          this.$refs.ruleForm.clearValidate([
            "q_data.realname",
            "q_data.country",
            "q_data.province",
            "q_data.city",
            "q_data.zcode",
            "q_data.address_one",
            "q_data.mobile",
          ]);
        });
      }
    },
    // 获取详情
    getOrderInfo() {
      orderInfo({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        order_id: this.id,
      }).then((res) => {
        if (res.code == 1) {
          let forms = res.data;
          forms.receive_data.province = forms.receive_data.province.split("-")[0];
          forms.send_data.province = forms.send_data.province.split("-")[0];
          if (forms.q_data.province) {
            forms.q_data.province = forms.q_data.province.split("-")[0];
          }
          this.ruleForm = res.data;
          this.sendProvinceList = provinceListValue(this.countryList, forms.send_data.country);
          this.receiveProvinceList = provinceListValue(
            this.countryList,
            forms.receive_data.country
          );
          if (forms.q_data.country) {
            this.qProvinceList = provinceListValue(this.countryList, forms.q_data.country);
          }
          if (forms.q_package.q_time) {
            this.qendtimelist = findTimeIndex(this.qtimelist, forms.q_package.q_time);
          }
          this.is_qus = forms.is_qu == 1 ? true : false;
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 添加地址到地址列表、校验地址
    addAddress(formName, num) {
      var sendValidate = [
        "send_data.realname",
        "send_data.country",
        "send_data.province",
        "send_data.city",
        "send_data.zcode",
        "send_data.address_one",
        "send_data.mobile",
      ];
      var receiveValidate = [
        "receive_data.realname",
        "receive_data.country",
        "receive_data.province",
        "receive_data.city",
        "receive_data.zcode",
        "receive_data.address_one",
        "receive_data.mobile",
      ];
      var validList = num == 1 ? sendValidate : receiveValidate;
      // 校验所有需要的字段
      var allValid = true;
      // if(num == 1){
      validList.forEach((field) => {
        this.$refs[formName].validateField(field, (errorMessage) => {
          if (errorMessage) {
            allValid = false;
          } else {
          }
        });
      });
      // 如果所有需要校验的字段都有效，则提交表单
      if (allValid) {
        // 执行表单提交逻辑
        if (num == 1) {
          addressAdd({
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
            user_type: 1,
            ...this.ruleForm.send_data,
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else if (num == 2) {
          addressAdd({
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
            user_type: 2,
            ...this.ruleForm.receive_data,
          })
            .then((res) => {
              if (res.code == 1) {
                this.$message.success(res.msg);
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        } else {
          checkAddress({
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
            receive_data: {
              ...this.ruleForm.receive_data,
              province: this.ruleForm.receive_data.province.split("-")[0],
            },
          })
            .then((res) => {
              if (res.code == 1) {
                this.checkVisible = true;
                this.checkInfo = res.data;
                // this.$message.success(res.msg)
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.$message.error(err.msg);
            });
        }
      }
    },
    // 关闭校验地址弹框
    closeCheckDialog() {
      this.checkVisible = false;
      this.checkInfo = {};
    },
    // 关闭校验地址弹框
    closeCheck() {
      this.checkVisible = false;
      this.checkInfo = {};
    },
    // 获取列表
    getAddress() {
      address({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        page: 0,
      }).then((res) => {
        if (res.code == 1) {
          res.data.list.forEach((item) => {
            item.user_type == 1 ? this.send_list.push(item) : this.receive_list.push(item);
          });
        }
      });
    },
    // 获取FBA地址
    getFbaList() {
      fbaList({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
      }).then((res) => {
        this.fbaLists = res.data;
      });
    },
    // 获取物流渠道
    getServiceNameList() {
      serviceListnew({
        user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
      }).then((res) => {
        let ups = [
          {
            label: "Customer Supplied Package",
          },
          {
            label: "UPS Letter",
          },
          {
            label: "Tube",
          },
          {
            label: "PAK",
          },
          {
            label: "UPS Express Box",
          },
          {
            label: "UPS 25KG Box",
          },
          {
            label: "UPS 10KG Box",
          },
          {
            label: "Pallet",
          },
          {
            label: "Small Express Box",
          },
          {
            label: "Medium Express Box",
          },
          {
            label: "Large Express Box",
          },
          {
            label: "Flats",
          },
          {
            label: "Parcels",
          },
          {
            label: "BPM",
          },
          {
            label: "First Class",
          },
          {
            label: "Priority",
          },
          {
            label: "Machinables",
          },
          {
            label: "Irregulars",
          },
          {
            label: "Parcel Post",
          },
          {
            label: "BPM Parcel",
          },
          {
            label: "Media Mail",
          },
          {
            label: "BPM Flat",
          },
          {
            label: "Standard Flat",
          },
        ];
        let FedEx = [
          {
            label: "YOUR_PACKAGING",
          },
          {
            label: "FEDEX_BOX",
          },
          {
            label: "FEDEX_ENVELOPE",
          },
          {
            label: "FEDEX_PAK",
          },
          {
            label: "FEDEX_TUBE",
          },
          {
            label: "FEDEX_SMALL_BOX",
          },
          // {
          //   label: 'FEDEX_DOC'
          // }
        ];
        console.log(res, "sdf");
        this.channelList = res.data.map((item) => {
          // let child = item.type == 1 ? ups : (item.type == 0 ? FedEx : [])
          return {
            name: item,
            // child
          };
        });
      });
    },
    // 获取城市
    getCity() {
      fCity().then((res) => {
        this.countryList = transformCountryCity(res.data);
        this.sendProvinceList = provinceListValue(this.countryList, "United States");
        this.receiveProvinceList = provinceListValue(this.countryList, "United States");
      });
    },
    // 通过邮编获取州城市
    getCityInfo(num) {
      let zcode =
        num == 1
          ? this.ruleForm.send_data.zcode
          : num == 2
          ? this.ruleForm.receive_data.zcode
          : this.ruleForm.q_data.zcode;
      cityInfo({
        zcode,
      }).then((res) => {
        if (res.code == 1) {
          if (num == 1) {
            this.ruleForm.send_data.country = "United States";
            this.$set(this.ruleForm.send_data, "province", res.data.province);
            this.$set(this.ruleForm.send_data, "city", res.data.city);
            this.sendProvinceList = provinceListValue(this.countryList, "United States");
          } else if (num == 2) {
            this.ruleForm.receive_data.country = "United States";
            this.$set(this.ruleForm.receive_data, "province", res.data.province);
            this.$set(this.ruleForm.receive_data, "city", res.data.city);
            this.receiveProvinceList = provinceListValue(this.countryList, "United States");
          } else if (num == 3) {
            this.ruleForm.q_data.country = "United States";
            this.$set(this.ruleForm.q_data, "province", res.data.province);
            this.$set(this.ruleForm.q_data, "city", res.data.city);
            this.qProvinceList = provinceListValue(this.countryList, "United States");
          }
        }
      });
    },
    //选取发件国家时获取省份
    handleSendChange(value) {
      this.sendProvinceList = provinceListValue(this.countryList, value);
      delete this.ruleForm.send_data.province;
    },
    //选取收件国家时获取省份
    handleReceiveChange(value) {
      this.receiveProvinceList = provinceListValue(this.countryList, value);
      delete this.ruleForm.receive_data.province;
    },
    //选取预约国家时获取省份
    handleQChange(value) {
      this.qProvinceList = provinceListValue(this.countryList, value);
      delete this.ruleForm.q_data.province;
    },
    // 费用预测
    handleFree(formName) {
      var validList = [
        "send_data.realname",
        "send_data.country",
        "send_data.province",
        "send_data.city",
        "send_data.zcode",
        "send_data.address_one",
        "send_data.mobile",
        "receive_data.realname",
        "receive_data.country",
        "receive_data.province",
        "receive_data.city",
        "receive_data.zcode",
        "receive_data.address_one",
        "receive_data.mobile",
      ];
      var qlist = [
        "q_data.realname",
        "q_data.country",
        "q_data.province",
        "q_data.city",
        "q_data.zcode",
        "q_data.address_one",
        "q_data.mobile",
        "q_package.realname",
        "q_package.package_all_num",
        "q_package.all_weight",
        "q_package.q_date",
        "q_package.q_time",
        "q_package.q_endtime",
      ];
      if (this.is_qus) {
        validList = validList.concat(qlist);
      }
      this.ruleForm.package_list.forEach((item, index) => {
        validList.push(`package_list[${index}][Sku]`);
        validList.push(`package_list[${index}][pa_num]`);
        validList.push(`package_list[${index}][pa_length]`);
        validList.push(`package_list[${index}][pa_lb]`);
      });

      this.ruleForm.project_list.forEach((item, index) => {
        validList.push(`project_list[${index}][code]`);
        validList.push(`project_list[${index}][unit_of]`);
        validList.push(`project_list[${index}][unit_price]`);
        validList.push(`project_list[${index}][product_info]`);
        validList.push(`project_list[${index}][pack_kind]`);
        validList.push(`project_list[${index}][num]`);
        validList.push(`project_list[${index}][suttle]`);
        validList.push(`project_list[${index}][chandi]`);
      });

      // 校验所有需要的字段
      var allValid = true;
      validList.forEach((field) => {
        this.$refs[formName].validateField(field, (errorMessage) => {
          if (errorMessage) {
            allValid = false;
          } else {
          }
        });
      });
      if (allValid) {
        let form = {
          ...this.ruleForm,
          user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
        };
        let package_list = form.package_list.map((item) => {
          let pa_unit = this.pa_unit == "磅/英寸" ? 1 : 2;
          return {
            ...item,
            pa_unit,
            pa_type: "",
            pa_height: item.pa_height || 1,
            pa_length: item.pa_length || 1,
            pa_width: item.pa_width || 1
          };
        });
        form.package_list = package_list;
        form.receive_data.province = form.receive_data.province.split("-")[0];
        form.send_data.province = form.send_data.province.split("-")[0];
        if (form.q_data.province) {
          form.q_data.province = form.q_data.province.split("-")[0];
        }

        if (form.receive_data.remark || form.receive_data.remark == "") {
          delete form.receive_data.remark;
        }
        if (!form.receive_data.email) {
          form.receive_data.email = "";
        }
        if (form.receive_data.id) {
          delete form.receive_data.id;
        }
        if (form.receive_data.user_type) {
          delete form.receive_data.user_type;
        }
        if (form.send_data.remark || form.send_data.remark == "") {
          delete form.send_data.remark;
        }
        if (form.send_data.user_type) {
          delete form.send_data.user_type;
        }
        if (form.send_data.id) {
          delete form.send_data.id;
        }
        if (!form.send_data.email) {
          form.send_data.email = "";
        }
        if (!form.goods_info.hb_order_sn) {
          form.goods_info.hb_order_sn = "";
        }

        this.freeLoading = true;
        rates_new(form)
          .then((res) => {
            this.freeLoading = false;
            if (res.code == 1) {
              this.$message.success(res.msg);
              this.freeInfo = res.data;
              this.freeVisible = true;
            } else {
              this.$message.error(res.msg);
            }
          })
          .catch((err) => {
            this.freeLoading = false;
            this.$message.error("预测费率失败！");
          });
      }
    },
    // 获取费用预测信息
    getRatesInfo(info) {
      this.ratesMoney = info.all_money;
      this.ratesWeight = info.weight;
      this.ruleForm.goods_info.service_type = info.service_type;
      // this.pa_type_list = this.channelList.find(item => {return item.name == info.service_name}) ? this.channelList.find(item => {return item.name == info.service_name}).child : []
      this.$set(this.ruleForm.goods_info, "pservice_type", info.service_name);
      // this.ruleForm.package_list = this.ruleForm.package_list.map(item => ({
      //   ...item,
      //   // pa_type: item.pa_type || ''
      //   // this.pa_type_list[0].label
      // }));
      this.freeVisible = false;
    },
    // 关闭费用预测
    closeFreeDialog() {
      this.freeVisible = false;
    },
    // 选择自定义发货地址
    selectOneself(obj) {
      this.ruleForm.send_data = {
        ...obj,
      };
      this.sendProvinceList = provinceListValue(this.countryList, obj.country);
      this.sendSelectValue = [];
    },
    // 选择渠道显示包裹类型
    changePservice(e) {
      // this.pa_type_list = this.channelList.find(item => {return item.name == e}) ? this.channelList.find(item => {return item.name == e}).child : []
    },
    // 选择地址列表的发货地址
    selectSendAddr(e) {
      let obj = this.send_list.find((item) => {
        return item.id == e;
      });
      if (obj) {
        this.ruleForm.send_data = {
          ...obj,
          province: obj.province.split("-")[0],
        };
        this.sendProvinceList = provinceListValue(this.countryList, obj.country);
      }
    },
    // 选择地址列表的收货地址
    selectReceiveAddr(e) {
      let obj = this.receive_list.find((item) => {
        return item.id == e;
      });
      if (obj) {
        this.ruleForm.receive_data = {
          ...obj,
          province: obj.province.split("-")[0],
        };
        this.receiveProvinceList = provinceListValue(this.countryList, obj.country);
        this.FbaValue = [];
      }
    },
    // 选择FBA地址的收货地址
    selectFBAAddr(e) {
      let obj = this.fbaLists.find((item) => {
        return item.id == e;
      });
      if (obj) {
        this.ruleForm.receive_data = {
          ...obj,
          province: obj.province.split("-")[0],
        };
        this.receiveProvinceList = provinceListValue(this.countryList, obj.country);
        this.receiveSelectValue = [];
      }
    },
    // 获取默认地址
    // getDefaultAddress(){
    //   defaultAddress({
    //     user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
    //   }).then(res => {
    //     if(res.code == 1){
    //       let j = res.data.j_address // 收件
    //       let s = res.data.s_address // 收件
    //       if(j){
    //         this.region1 = [j.province,j.city,j.area]
    //         this.ruleForm.sender = j.realname, //收件人姓名
    //         this.ruleForm.sendPhone = j.mobile, //收件人联系电话
    //         this.ruleForm.jjr_sheng = j.province, //收件地址-省
    //         this.ruleForm.jjr_shi = j.city, //收件地址-市
    //         this.ruleForm.jjr_qu = j.area, //收件地址-区
    //         this.ruleForm.sendAddress = j.address //收件地址-详细地址
    //       }
    //       if(s){
    //         this.region2 = [s.province,s.city,s.area]
    //         this.ruleForm.collector = s.realname, //收件人姓名
    //         this.ruleForm.collectPhone = s.mobile, //收件人联系电话
    //         this.ruleForm.sjr_sheng = s.province, //收件地址-省
    //         this.ruleForm.sjr_shi = s.city, //收件地址-市
    //         this.ruleForm.sjr_qu = s.area, //收件地址-区
    //         this.ruleForm.collectAddress = s.address //收件地址-详细地址
    //       }
    //     }
    //   })
    // },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.ruleForm,
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
          };
          let package_list = form.package_list.map((item) => {
            let pa_unit = this.pa_unit == "磅/英寸" ? 1 : 2;
            return {
              ...item,
              pa_unit,
              pa_height: item.pa_height || 1,
              pa_length: item.pa_length || 1,
              pa_width: item.pa_width || 1
              // pa_type:''
            };
          });
          form.package_list = package_list;
          form.receive_data.province = form.receive_data.province.split("-")[0];
          form.send_data.province = form.send_data.province.split("-")[0];
          if (form.q_data.province) {
            form.q_data.province = form.q_data.province.split("-")[0];
          }
          if (form.receive_data.remark || form.receive_data.remark == "") {
            delete form.receive_data.remark;
          }
          if (form.receive_data.id) {
            delete form.receive_data.id;
          }
          if (form.receive_data.user_type) {
            delete form.receive_data.user_type;
          }
          if (form.send_data.remark || form.send_data.remark == "") {
            delete form.send_data.remark;
          }
          if (!form.goods_info.hb_order_sn) {
            form.goods_info.hb_order_sn = "";
          }
          if (form.send_data.user_type) {
            delete form.send_data.user_type;
          }
          if (form.send_data.id) {
            delete form.send_data.id;
          }
          this.orderLoading = true;
          createorder_new(form)
            .then((res) => {
              this.orderLoading = false;
              if (res.code == 1 && res.data.status == 1) {
                this.$message.success(res.msg);
                if (this.id) {
                  this.$emit("orderSuccess");
                } else {
                  this.$router.push({ path: "/bill/list" });
                }
              } else {
                if (res.code == 1) {
                  this.$message.error(res.data.text);
                } else {
                  this.$message.error(res.msg);
                }
              }
            })
            .catch((err) => {
              this.orderLoading = false;
              this.$message.error("生成订单失败！");
            });
        } else {
          return false;
        }
      });
    },
    // 门店发货
    storeForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.ruleForm,
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
          };
          let package_list = form.package_list.map((item) => {
            let pa_unit = this.pa_unit == "磅/英寸" ? 1 : 2;
            return {
              ...item,
              pa_unit,
              pa_height: item.pa_height || 1,
              pa_length: item.pa_length || 1,
              pa_width: item.pa_width || 1
              // pa_type:'Your Packaging'
            };
          });
          form.package_list = package_list;
          form.receive_data.province = form.receive_data.province.split("-")[0];
          form.send_data.province = form.send_data.province.split("-")[0];
          if (form.receive_data.remark || form.receive_data.remark == "") {
            delete form.receive_data.remark;
          }
          if (form.receive_data.id) {
            delete form.receive_data.id;
          }
          if (form.receive_data.user_type) {
            delete form.receive_data.user_type;
          }
          if (form.send_data.remark || form.send_data.remark == "") {
            delete form.send_data.remark;
          }
          if (!form.goods_info.hb_order_sn) {
            form.goods_info.hb_order_sn = "";
          }
          if (form.send_data.user_type) {
            delete form.send_data.user_type;
          }
          if (form.send_data.id) {
            delete form.send_data.id;
          }
          this.storeLoading = true;
          storeOrder_new(form)
            .then((res) => {
              this.storeLoading = false;
              if (res.code == 1) {
                this.$message.success(res.msg);
                if (this.id) {
                  this.$emit("orderSuccess");
                } else {
                  this.$router.push({ path: "/bill/list" });
                }
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.storeLoading = false;
              this.$message.error("生成订单失败！");
            });
        } else {
          return false;
        }
      });
    },
    // 保存草稿
    submitDraftForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let form = {
            ...this.ruleForm,
            user_code: JSON.parse(window.localStorage.getItem("userInfo1")).user_code,
          };
          let package_list = form.package_list.map((item) => {
            let pa_unit = this.pa_unit == "磅/英寸" ? 1 : 2;
            return {
              ...item,
              pa_unit,
              pa_height: item.pa_height || 1,
              pa_length: item.pa_length || 1,
              pa_width: item.pa_width || 1
              // pa_type:'Your Packaging'
            };
          });
          form.package_list = package_list;
          form.receive_data.province = form.receive_data.province.split("-")[0];
          form.send_data.province = form.send_data.province.split("-")[0];
          if (form.q_data.province) {
            form.q_data.province = form.q_data.province.split("-")[0];
          }
          if (form.receive_data.remark || form.receive_data.remark == "") {
            delete form.receive_data.remark;
          }
          if (form.receive_data.id) {
            delete form.receive_data.id;
          }
          if (form.receive_data.user_type) {
            delete form.receive_data.user_type;
          }
          if (form.send_data.remark || form.send_data.remark == "") {
            delete form.send_data.remark;
          }
          if (form.send_data.user_type) {
            delete form.send_data.user_type;
          }
          if (form.send_data.id) {
            delete form.send_data.id;
          }
          if (!form.goods_info.hb_order_sn) {
            form.goods_info.hb_order_sn = "";
          }
          this.draftLoading = true;
          saveOrder_new(form)
            .then((res) => {
              this.draftLoading = false;
              if (res.code == 1) {
                this.$message.success(res.msg);
                if (this.id) {
                  this.$emit("orderSuccess");
                } else {
                  this.$router.push({ path: "/bill/list" });
                }
              } else {
                this.$message.error(res.msg);
              }
            })
            .catch((err) => {
              this.draftLoading = false;
              this.$message.error("保存草稿失败！");
            });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.sendSelectValue = [];
      this.receiveSelectValue = [];
      this.sendProvinceList = [];
      this.receiveProvinceList = [];
      this.qPackageTypeList = [];
      this.qProvinceList = [];
      this.pa_type_list = [
        {
          label: "Your 10kg Box",
        },
        {
          label: "Your 25kg Box",
        },
        {
          label: "Your Envelope",
        },
        {
          label: "Your Extra Large Box",
        },
        {
          label: "Your Large Box",
        },
        {
          label: "Your Medium Box",
        },
        {
          label: "Your Pak",
        },
        {
          label: "Your Small Box",
        },
        {
          label: "Your Tube",
        },
        {
          label: "Your Packaging",
        },
      ];
      this.pa_unit = "磅/英寸";
      this.FbaValue = "";
      this.freeInfo = {};
      this.checkInfo = {};
      this.ratesMoney = "";
      this.ratesWeight = "";
      this.ruleForm = {
        goods_info: {
          is_sign: 0, // 默认为0
          service_type: "", //服务商
          USPSEndorsement: 1, //1需要退货服务   5不需要服务
        },
        package_list: [
          {
            Sku: "001", //Sku
            insurance: 0, //保险金额（默认0）
            pa_height: '', //高度
            pa_lb: "", //重量
            pa_length: '', //长度
            pa_num: 1, //数量
            pa_type: "Your Packaging", //包裹类型 字段必须存在 值可为空
            pa_width: '', //宽度
          },
        ],
        project_list: [
          {
            code: "",
            unit_of: "",
            unit_price: "",
            product_info: "",
            pack_kind: "",
            num: "",
            suttle: "",
            chandi: "",
          },
        ],
        receive_data: {
          company: "",
          address_two: "",
          email: "",
        },
        send_data: {
          company: "",
          address_two: "",
          email: "",
        },
        is_qu: 0, //是否安排取货0-否1-是
        q_data: {
          company: "",
          address_two: "",
        },
        q_package: {},
      };
      this.is_qus = false;
      this.qendtimelist = [];
      this.$refs[formName].resetFields();
    },
    // // 地址弹窗
    // selectAddress(num){
    //   this.isType = num
    //   this.formVisible = true
    // },
    // // 关闭弹框
    // closeDialog() {
    //   this.isType = 1;
    //   this.formVisible = false;
    // },
    //新增、修改成功的回调
    // editSuccess(record,isType) {
    //   this.formVisible = false;
    //   if(isType==1){
    //     this.region1 = [record.province,record.city,record.area]
    //     this.ruleForm.sender = record.realname, //收件人姓名
    //     this.ruleForm.sendPhone = record.mobile, //收件人联系电话
    //     this.ruleForm.jjr_sheng = record.province, //收件地址-省
    //     this.ruleForm.jjr_shi = record.city, //收件地址-市
    //     this.ruleForm.jjr_qu = record.area, //收件地址-区
    //     this.ruleForm.sendAddress = record.address //收件地址-详细地址
    //   }
    //   if(isType==2){
    //     this.region2 = [record.province,record.city,record.area]
    //     this.ruleForm.collector = record.realname, //收件人姓名
    //     this.ruleForm.collectPhone = record.mobile, //收件人联系电话
    //     this.ruleForm.sjr_sheng = record.province, //收件地址-省
    //     this.ruleForm.sjr_shi = record.city, //收件地址-市
    //     this.ruleForm.sjr_qu = record.area, //收件地址-区
    //     this.ruleForm.collectAddress = record.address //收件地址-详细地址
    //   }
    // },
    // 添加包裹
    addParcel() {
      let obj = {
        Sku: "001", //Sku
        insurance: 0, //保险金额（默认0）
        pa_height: '', //高度
        pa_lb: "", //重量
        pa_length: '', //长度
        pa_num: 1, //数量
        pa_type: "Your Packaging", //包裹类型 字段必须存在 值可为空
        pa_width: '', //宽度
      };
      this.ruleForm.package_list.push(obj);
    },
    // 删除包裹
    delParcel(num) {
      this.ruleForm.package_list.splice(num, 1);
    },
    // 复制文件
    copyParcel(num, item) {
      let pa_num = item.pa_num;
      if (item.pa_num > 1) {
        this.ruleForm.package_list[num].pa_num = 1;
        for (var i = 1; i < pa_num; i++) {
          let obj = {
            ...item,
            pa_num: 1,
          };
          this.ruleForm.package_list.push({ ...obj });
        }
      } else {
        this.ruleForm.package_list.push({ ...item });
      }
    },
    // 添加申报项目
    addProject() {
      let obj = {
        code: "",
        unit_of: "",
        unit_price: "",
        product_info: "",
        pack_kind: "",
        num: "",
        suttle: "",
        chandi: "",
      };
      this.ruleForm.project_list.push(obj);
    },
    delProject(num) {
      this.ruleForm.project_list.splice(num, 1);
    },
  },
};
</script>
<style lang="scss" scoped>
.place {
  background: #fff;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  overflow-y: scroll;
  .place-title {
    width: 100%;
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    color: #409eff;
  }
  .border-col {
    border-bottom: 1px solid #409eff;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
}
/deep/.el-form-item__content {
  text-align: left;
}
.add-select-btn {
  margin-left: 30px;
}
// 包裹信息
.parcel-item,
.parcel-item1 {
  width: 100%;
  background: rgba(64, 158, 255, 0.1);
  border-radius: 5px;
  box-sizing: border-box;
  padding: 10px;
  margin-bottom: 10px;
  .parcel-item-label {
    font-size: 14px;
    text-align: left;
    margin: 5px 0;
  }
  .parcel-top {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bee6f7;
    margin-bottom: 10px;
    padding-bottom: 10px;
    .parcel-title {
      font-size: 14px;
    }
  }
  .parcel-lwh {
    display: flex;
    align-items: center;
    /deep/ input::-webkit-outer-spin-button,
    /deep/ input::-webkit-inner-spin-button {
      -webkit-appearance: none !important;
    }
    /deep/ input[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .place-box {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
.tips {
  font-size: 14px;
  color: red;
  padding-left: 10px;
}
.bttn {
  text-align: right;
  margin-top: 10px;
  font-size: 14px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.free-rate {
  font-size: 14px;
  display: flex;
  align-items: center;
  margin: 0 10px;
  span {
    color: #e6a23c;
    font-size: 16px;
  }
}
.check-dialog {
  /deep/.el-dialog__body {
    padding: 0 20px !important;
  }
}
</style>
